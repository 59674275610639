import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// components
import Btn from '@/components/Button/Btn.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import TextareaInput from '@/components/FormInputs/TextAreaInput/TextAreaInput.vue';
import LabelSelect from '@/components/FormInputs/LabelSelect/LabelSelect.vue';
import CheckboxInput from '@/components/FormInputs/CheckboxInput/CheckboxInput.vue';
import MultiImageUpload from '@/components/MultiImageUpload/MultiImageUpload.vue';
let UpdateDiscussionPage = class UpdateDiscussionPage extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            title: '',
            discussion: '',
            has_restricted_words: false,
            submit_restricted_words: false,
            labels: [],
            images: [],
            video_embed_url: ''
        };
        this.errors = {
            title: [],
            discussion: [],
            video_embed_url: [],
            images: [],
            labels: [],
            restricted_words: [],
            submit_restricted_words: [],
        };
        this.loading = false;
        this.coverImage = '';
        this.canEdit = false;
        this.imageData = [];
        this.uploadedImages = [];
    }
    async mounted() {
        // check if the current user has access to edit this discussion.
        this.canEdit = this.discussion.user_id === this.profile.id;
        if (!this.discussion.slug || !this.canEdit) {
            this.$router.push({ name: 'discussions' });
        }
        this.form.title = this.discussion.title ?? '';
        this.form.discussion = this.discussion.discussion ?? '';
        this.form.video_embed_url = this.discussion.video_embed_url ?? '';
        // @ts-ignore
        this.form.labels = this.discussion.labels;
        this.form.images = this.discussion.images;
        this.getCoverImage();
    }
    getCoverImage() {
        if (this.discussion.images && this.discussion.images.length > 0) {
            this.coverImage = this.discussion.images[0].public_url;
        }
        else {
            // TODO: get images from bank of images of same topic?
        }
    }
    async submit() {
        let formData = new FormData();
        // make string array out of uploaded images for formData upload
        for (var i = 0; i < this.uploadedImages.length; i++) {
            let file = this.uploadedImages[i];
            formData.append('images[]', file, 'image.jpeg');
        }
        // append form data
        formData.append('_method', 'put');
        formData.append('title', this.form.title);
        formData.append('discussion', this.form.discussion);
        formData.append('submit_restricted_words', this.form.submit_restricted_words ? '1' : '0');
        formData.append('has_restricted_words', this.form.has_restricted_words ? '1' : '0');
        formData.append('video_embed_url', this.form.video_embed_url);
        let labelCount = this.form.labels ? this.form.labels.length : 0;
        for (var i = 0; i < labelCount; i++) {
            // @ts-ignore
            let label = this.form.labels[i];
            formData.append('labels[]', label);
        }
        try {
            this.loading = true;
            const payload = {
                formData: formData,
                slug: this.discussion.slug
            };
            const resp = await this.$store.dispatch('discussions/update', payload);
            this.loading = false;
            this.$router.push({ path: `/discussion/${resp.slug}` });
        }
        catch (e) {
            this.loading = false;
            if (e.data.messages) {
                this.errors = e.data.messages;
            }
        }
    }
    async deleteExistingImage(value) {
        const imageToDelete = {
            id: value,
            imageable_type: 'discussion',
            imageable_id: this.discussion.id
        };
        try {
            await this.$store.dispatch('app/deleteImage', imageToDelete);
            await this.$store.dispatch('discussions/getDiscussionBySlug', this.discussion.slug);
        }
        catch (e) {
            console.log('UpdateDiscussion', e);
        }
    }
};
__decorate([
    Getter('activeDiscussion', { namespace: 'discussions' })
], UpdateDiscussionPage.prototype, "discussion", void 0);
__decorate([
    Getter('getProfile', { namespace: 'user' })
], UpdateDiscussionPage.prototype, "profile", void 0);
UpdateDiscussionPage = __decorate([
    Component({
        components: {
            Btn,
            CoverImage,
            TextInput,
            TextareaInput,
            LabelSelect,
            CheckboxInput,
            MultiImageUpload
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | update discussion',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic discussion',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/discussion/update` }],
            };
        },
    })
], UpdateDiscussionPage);
export default UpdateDiscussionPage;
