import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import MarkdownFormatter from '@/components/MarkdownFormatter/MarkdownFormatter.vue';
let CommentQuote = class CommentQuote extends Vue {
    constructor() {
        super(...arguments);
        this.showFullReplyingTo = false;
    }
    get replyingToText() {
        if (this.showFullReplyingTo) {
            return this.comment.comment;
        }
        else {
            return this.comment.comment.substring(0, 100);
        }
    }
};
__decorate([
    Prop()
], CommentQuote.prototype, "comment", void 0);
CommentQuote = __decorate([
    Component({ components: { MarkdownFormatter } })
], CommentQuote);
export default CommentQuote;
