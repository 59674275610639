import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
// componenets
import Gallery from '@/components/Gallery/Gallery.vue';
import LabelList from '@/components/Label/LabelList/LabelList.vue';
import MarkdownFormatter from '@/components/MarkdownFormatter/MarkdownFormatter.vue';
let DiscussionContent = class DiscussionContent extends Vue {
};
__decorate([
    Prop()
], DiscussionContent.prototype, "discussion", void 0);
DiscussionContent = __decorate([
    Component({
        components: {
            Gallery,
            LabelList,
            MarkdownFormatter
        }
    })
], DiscussionContent);
export default DiscussionContent;
