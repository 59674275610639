import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
// components
import Btn from '@/components/Button/Btn.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import TextareaInput from '@/components/FormInputs/TextAreaInput/TextAreaInput.vue';
import LabelSelect from '@/components/FormInputs/LabelSelect/LabelSelect.vue';
import CheckboxInput from '@/components/FormInputs/CheckboxInput/CheckboxInput.vue';
import MultiImageUpload from '@/components/MultiImageUpload/MultiImageUpload.vue';
let CreateDiscussionPage = class CreateDiscussionPage extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            title: '',
            discussion: '',
            has_restricted_words: false,
            submit_restricted_words: false,
            labels: [],
            images: [],
            video_embed_url: ''
        };
        this.errors = {
            title: [],
            discussion: [],
            video_embed_url: [],
            images: [],
            labels: [],
            restricted_words: [],
            submit_restricted_words: [],
        };
        this.loading = false;
        this.error = '';
        this.imageData = [];
        this.uploadedImages = [];
    }
    async mounted() { }
    async submit() {
        let formData = new FormData();
        // make string array out of uploaded images for formData upload
        for (var i = 0; i < this.uploadedImages.length; i++) {
            let file = this.uploadedImages[i];
            formData.append('images[]', file, 'image.jpeg');
        }
        // append form data
        formData.append('title', this.form.title);
        formData.append('discussion', this.form.discussion);
        formData.append('video_embed_url', this.form.video_embed_url);
        formData.append('submit_restricted_words', this.form.submit_restricted_words ? '1' : '0');
        formData.append('has_restricted_words', this.form.has_restricted_words ? '1' : '0');
        let labelCount = this.form.labels ? this.form.labels.length : 0;
        for (var i = 0; i < labelCount; i++) {
            // @ts-ignore
            let label = this.form.labels[i];
            formData.append('labels[]', label);
        }
        try {
            this.loading = true;
            const resp = await this.$store.dispatch('discussions/create', formData);
            this.loading = false;
            this.$router.push({ path: `/discussion/${resp.slug}` });
        }
        catch (e) {
            this.loading = false;
            if (e.data.messages) {
                this.errors = e.data.messages;
            }
            if (e.data.restricted_words) {
                this.errors.restricted_words = e.data.restricted_words;
            }
            this.error = e.message;
        }
    }
};
CreateDiscussionPage = __decorate([
    Component({
        components: {
            Btn,
            CoverImage,
            TextInput,
            TextareaInput,
            LabelSelect,
            CheckboxInput,
            MultiImageUpload,
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | create discussion',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic discussion',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/discussion/create` }],
            };
        },
    })
], CreateDiscussionPage);
export default CreateDiscussionPage;
