import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import LabelPill from '@/components/Label/LabelPill/LabelPill.vue';
let LabelList = class LabelList extends Vue {
};
__decorate([
    Prop()
], LabelList.prototype, "labels", void 0);
__decorate([
    Prop({ default: 'left' })
], LabelList.prototype, "align", void 0);
__decorate([
    Prop({ default: false })
], LabelList.prototype, "isClickable", void 0);
LabelList = __decorate([
    Component({ components: { LabelPill } })
], LabelList);
export default LabelList;
