import { __decorate } from "tslib";
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
let ImageUpload = class ImageUpload extends Vue {
    constructor() {
        super(...arguments);
        this.imageData = []; // used to preview images
        // setAsCoverImage(id:number) {
        //     this.$emit('setCoverImage', id)
        // }
    }
    onImageChange(event) {
        var input = event.target;
        if (input.files && input.files.length > 0) {
            // @ts-ignore
            let imageCount = this.$refs.images.files.length;
            for (var i = 0; i < imageCount; i++) {
                // @ts-ignore
                let file = this.$refs.images.files[i];
                // @ts-ignore - UPDATE form.images array
                this.uploadedImages.push(file);
                this.updateImageData(file);
            }
        }
    }
    updateImageData(file) {
        /**
         * UPDATE Base64 image array
         */
        var reader = new FileReader();
        reader.onload = (e) => {
            // @ts-ignore
            // Read image as base64 and set to imageData
            this.imageData.push(e.target.result);
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(file);
    }
    deleteUploadedImage(index) {
        if (index !== -1) {
            // @ts-ignore - remove image from both arrays
            this.uploadedImages.splice(index, 1);
            this.imageData.splice(index, 1);
        }
        // @ts-ignore - clear input
        // console.log(`MultiImageUpload.vue - 102 - this.$refs.images`, this.$refs.images);
        // this.$refs.images.value = ""
    }
    deleteExistingImage(id) {
        this.$emit('delete', id);
    }
};
__decorate([
    PropSync('value', { type: Array })
], ImageUpload.prototype, "uploadedImages", void 0);
__decorate([
    Prop({ type: Array })
], ImageUpload.prototype, "images", void 0);
ImageUpload = __decorate([
    Component
], ImageUpload);
export default ImageUpload;
