import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
//components
import Btn from '@/components/Button/Btn.vue';
let AgreeDisagreeButtons = class AgreeDisagreeButtons extends Vue {
    mounted() { }
    async agree() {
        await this.$store.dispatch('engagements/agree', {
            agreeable_id: this.engagement_id,
            agreeable_type: this.engagement_type,
        });
    }
    async disagree() {
        await this.$store.dispatch('engagements/disagree', {
            disagreeable_id: this.engagement_id,
            disagreeable_type: this.engagement_type,
        });
    }
};
__decorate([
    Prop({ default: false })
], AgreeDisagreeButtons.prototype, "hasAgreed", void 0);
__decorate([
    Prop({ default: false })
], AgreeDisagreeButtons.prototype, "hasDisagreed", void 0);
__decorate([
    Prop({ default: 0 })
], AgreeDisagreeButtons.prototype, "agreesCount", void 0);
__decorate([
    Prop({ default: 0 })
], AgreeDisagreeButtons.prototype, "disagreesCount", void 0);
__decorate([
    Prop()
], AgreeDisagreeButtons.prototype, "engagement_id", void 0);
__decorate([
    Prop()
], AgreeDisagreeButtons.prototype, "engagement_type", void 0);
AgreeDisagreeButtons = __decorate([
    Component({
        components: { Btn }
    })
], AgreeDisagreeButtons);
export default AgreeDisagreeButtons;
