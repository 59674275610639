import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
//components
import AgreeDisagreeButtons from '../AgreeDisagreeButtons/AgreeDisagreeButtons.vue';
import CreateComment from '@/components/Comments/CreateComment/CreateComment.vue';
import CommentQuote from '@/components/Comments/CommentQuote/CommentQuote.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import Btn from '@/components/Button/Btn.vue';
import EditComment from '@/components/Comments/EditComment/EditComment.vue';
import store from '@/store';
let EngagementFooter = class EngagementFooter extends Vue {
    constructor() {
        super(...arguments);
        this.shouldShowCommentInput = false;
        this.shouldShowCommentEdit = false;
        this.showSecondaryIcons = false;
        // engagements
        this.hasAgreed = false;
        this.hasDisagreed = false;
        this.hasReported = false;
        // commenting
        this.commenting = true;
        // editing
        this.canEdit = false;
        // Reporting
        this.reporting = false;
        this.reportComment = '';
        this.showReportingForm = false;
        this.form = {
            reportable_id: '',
            reportable_type: '',
            comment: ''
        };
        this.errors = {
            comment: [],
        };
    }
    onItemUpdate() {
        this.checkEngagements();
    }
    async mounted() {
        this.shouldShowCommentInput = this.isDesktop ? this.showCommentInput : false;
        await this.checkEngagements();
        // Reporting
        this.form.reportable_id = this.item.id;
        this.form.reportable_type = this.itemType;
        this.$nextTick(async () => {
            await store.dispatch('app/calcBreakPoint', window.innerWidth);
        });
    }
    /**
     * If we have supplied a parent ID then we should use this
     * instead of the engagement ID
     */
    get commentId() {
        return this.parent_id ? this.parent_id : this.engagement_id;
    }
    get commentType() {
        return this.parent_type ? this.parent_type : this.engagement_type;
    }
    get isDesktop() {
        return this.mediaSize >= 2;
    }
    get itemCommentLength() {
        if ('comments' in this.item) {
            return this.item?.comments ? this.item?.comments.reduce((count, current) => count + current.replies?.length, 0) + this.item?.comments?.length : 0;
        }
        else {
            return this.item.replies ? this.item.replies.length : 0;
        }
    }
    // Engagements
    async checkEngagements() {
        this.hasAgreed = !!(this.item?.agrees && this.item?.agrees.filter((item) => item.user_id === this.profile.id)[0]);
        this.hasDisagreed = !!(this.item?.disagrees && this.item?.disagrees.filter((item) => item.user_id === this.profile.id)[0]);
        this.hasReported = !!(this.item?.reports && this.item?.reports.filter((item) => item.user_id === this.profile.id)[0]);
        this.canEdit = this.item?.user_id === this.profile.id;
    }
    toggleComment() {
        this.shouldShowCommentInput = !this.shouldShowCommentInput;
    }
    toggleCommentEdit() {
        this.shouldShowCommentEdit = !this.shouldShowCommentEdit;
    }
    toggleSecondaryIcons() {
        this.showSecondaryIcons = !this.showSecondaryIcons;
        this.shouldShowCommentInput = false;
        this.showReportingForm = false;
        this.shouldShowCommentEdit = false;
    }
    toggleReporting() {
        this.showReportingForm = !this.showReportingForm;
    }
    // To delete an item we need to work out which type of item we are working with
    async deleteItem() {
        switch (this.itemType) {
            case 'discussion':
                if ('comments' in this.item) {
                    await this.$router.push({ name: 'discussions' });
                    await this.$store.dispatch('discussions/delete', this.item.slug);
                }
                break;
            case 'comment':
                await this.$store.dispatch('engagements/deleteComment', this.item.id);
                break;
        }
    }
    // To edit an item we need to work out which type of item we are working with
    editItem() {
        switch (this.itemType) {
            case 'discussion':
                this.$router.push({ name: 'update-discussion' });
                break;
            case 'comment':
                this.toggleCommentEdit();
                break;
        }
    }
    // To report an item we need to work out which type of item we are working with
    async report() {
        await this.$store.dispatch('engagements/report', this.form);
        this.showReportingForm = false;
    }
};
__decorate([
    Prop()
], EngagementFooter.prototype, "item", void 0);
__decorate([
    Prop()
], EngagementFooter.prototype, "itemType", void 0);
__decorate([
    Prop()
], EngagementFooter.prototype, "engagement_id", void 0);
__decorate([
    Prop()
], EngagementFooter.prototype, "engagement_type", void 0);
__decorate([
    Prop({ default: null })
], EngagementFooter.prototype, "parent_id", void 0);
__decorate([
    Prop({ default: null })
], EngagementFooter.prototype, "parent_type", void 0);
__decorate([
    Prop({ default: null })
], EngagementFooter.prototype, "replying_to", void 0);
__decorate([
    Prop({ default: false })
], EngagementFooter.prototype, "showCommentInput", void 0);
__decorate([
    Getter('mediaSize', { namespace: 'app' })
], EngagementFooter.prototype, "mediaSize", void 0);
__decorate([
    Getter('getProfile', { namespace: 'user' })
], EngagementFooter.prototype, "profile", void 0);
__decorate([
    Watch('item')
], EngagementFooter.prototype, "onItemUpdate", null);
EngagementFooter = __decorate([
    Component({
        components: {
            EditComment,
            AgreeDisagreeButtons,
            CreateComment,
            CommentQuote,
            TextInput, Btn
        }
    })
], EngagementFooter);
export default EngagementFooter;
