import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
//components
import Card from '@/components/Card/Card.vue';
import ProfileCard from '@/components/ProfileCard/ProfileCard.vue';
import LabelList from '@/components/Label/LabelList/LabelList.vue';
import AgreeDisagreeCount from '@/components/Engagements/AgreeDisagreeCount/AgreeDisagreeCount.vue';
import MarkdownFormatter from '@/components/MarkdownFormatter/MarkdownFormatter.vue';
let DiscussionCard = class DiscussionCard extends Vue {
    async click(id) {
        await this.$store.dispatch('discussions/setActiveDiscussion', this.discussion);
        this.$router.push({ path: `/discussion/${this.discussion.slug}` });
    }
    getCoverImage() {
        if (this.discussion && this.discussion.images.length > 0) {
            return this.discussion.images[0].public_url;
        }
        else {
            return null;
        }
    }
    handleClickEvent(e) {
        this.$emit("topic-changed", e);
    }
    get truncatedString() {
        const num = 300;
        if (this.discussion && this.discussion.discussion.length <= num) {
            return this.discussion.discussion;
        }
        return this.discussion.discussion.slice(0, num) + '...';
    }
    async pushHistoryState(url) {
        await history.pushState({ search: this.$store.state.discussions.searchTerm, page: this.$store.state.discussions.page }, 'Discussions', this.$route.fullPath);
        this.$router.push({ path: url });
    }
};
__decorate([
    Prop()
], DiscussionCard.prototype, "discussion", void 0);
DiscussionCard = __decorate([
    Component({
        components: {
            Card,
            ProfileCard,
            LabelList,
            AgreeDisagreeCount,
            MarkdownFormatter
        },
    })
], DiscussionCard);
export default DiscussionCard;
