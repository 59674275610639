import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// components
import Btn from '@/components/Button/Btn.vue';
import Sidebar from '@/components/Elements/Sidebar.vue';
import Gallery from '@/components/Gallery/Gallery.vue';
import Expander from '@/components/Expander/Expander.vue';
import SocialShare from '@/components/SocialShare/SocialShare.vue';
import ProfileCard from '@/components/ProfileCard/ProfileCard.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
import ExpanderMap from '@/components/Map/ExpanderMap/ExpanderMap.vue';
import OfferCard from '@/components/OfferCard/OfferCard.vue';
import LabelList from '@/components/Label/LabelList/LabelList.vue';
import SocialLinks from '@/components/SocialLinks/SocialLinks.vue';
import CreateComment from '@/components/Comments/CreateComment/CreateComment.vue';
import DiscussionContent from '@/views/Discussions/Sections/DiscussionContent.vue';
import CommentItem from '@/components/Comments/Comment/Comment.vue';
import Report from '@/components/Engagements/Report/Report.vue';
import MarkdownFormatter from '@/components/MarkdownFormatter/MarkdownFormatter.vue';
import EngagementFooter from '@/components/Engagements/EngagementFooter/EngagementFooter.vue';
import CommentList from '@/components/Comments/CommentList/CommentList.vue';
import OverlayBasic from '@/components/OverlayBasic/OverlayBasic.vue';
import MemberLookup from '@/views/Members/Components/MemberLookup.vue';
let DiscussionPage = class DiscussionPage extends Vue {
    constructor() {
        super(...arguments);
        this.coverImage = '';
        this.isFavourited = false;
        this.canEdit = false;
        // reporting
        this.reporting = false;
        this.reportComment = '';
        // commenting
        this.commentCount = 0;
        this.commenting = true;
        // create comment
        this.form = {
            comment: '',
            has_restricted_words: false,
            submit_restricted_words: false
        };
        this.errors = {
            comment: [],
            restricted_words: [],
            submit_restricted_words: [],
        };
        this.shareInfo = {};
        // Width Of Sidebar container
        this.sidebarContainerWidth = 0;
        // Is the sidebar fixed
        this.isSidebarFixed = false;
        // Send link to member
        this.showMemberSelect = false;
        this.display = false;
    }
    async onDiscussionUpdate() {
        this.checkEngagements();
        this.getCoverImage();
        const sidebarRef = this.$refs.sidebarContainer;
        this.sidebarContainerWidth = sidebarRef.clientWidth;
    }
    async mounted() {
        await this.getDiscussion();
        await this.checkEngagements();
        this.getCoverImage();
        await this.$store.dispatch('engagements/setEngagementType', {
            engagementType: 'discussion',
            engagementSlug: this.discussion.slug,
        });
        const sidebarRef = this.$refs.sidebarContainer;
        this.sidebarContainerWidth = sidebarRef.clientWidth;
        this.$nextTick(() => {
            this.shareInfo = {
                url: `${process.env.VUE_APP_URL}discussion/${this.$route.params.slug}`,
                title: this.discussion.title,
                description: 'Get involved in discussions with Africa Geographic members',
                quote: 'Get involved in discussions with Africa Geographic members',
                hashtags: `africa,africageographic,club`,
                twitterUser: 'africageo',
            };
        });
    }
    // When the component is created track the scroll position
    created() {
        window.addEventListener('resize', this.handleWidthChange);
    }
    // When the component is destroyed stop tracking to ensure lower memory use
    destroyed() {
        window.removeEventListener('resize', this.handleWidthChange);
    }
    // Update the scroll pos once scrolling
    handleWidthChange() {
        const sidebarRef = this.$refs.sidebarContainer;
        this.sidebarContainerWidth = sidebarRef.clientWidth;
    }
    async getDiscussion() {
        await this.$store.dispatch('discussions/getDiscussionBySlug', this.$route.params.slug);
    }
    getCoverImage() {
        if (this.discussion && this.discussion.images && this.discussion.images.length > 0) {
            this.coverImage = this.discussion.images[0].public_url;
        }
        else {
            // TODO: get images from bank of images of same topic?
        }
    }
    async favourite() {
        await this.$store.dispatch('discussions/favourite', this.discussion.slug);
        await this.checkEngagements();
    }
    // Engagements
    async checkEngagements() {
        if (this.discussion && this.discussion.favorite_discussions && this.discussion.favorite_discussions.length > 0) {
            this.isFavourited = !!this.discussion.favorite_discussions.filter((user) => user.id === this.profile.id)[0];
        }
        else {
            this.isFavourited = false;
        }
        this.canEdit = this.discussion.user_id === this.profile.id;
    }
    toggleCommenting() {
        this.commenting = !this.commenting;
        this.checkEngagements();
    }
    // edit discussion if you are the user that created it.
    edit() {
        this.$router.push({ name: 'update-discussion' });
    }
    async deleteDiscussion() {
        await this.$store.dispatch('discussions/delete', this.discussion.slug);
        await this.$router.push({ name: 'discussions' });
    }
    onSidebarFixed(value) {
        this.isSidebarFixed = value;
    }
    showConfirmation() {
        this.display = true;
    }
    hideConfirmation() {
        this.display = false;
    }
};
__decorate([
    Getter('activeDiscussion', { namespace: 'discussions' })
], DiscussionPage.prototype, "discussion", void 0);
__decorate([
    Getter('getProfile', { namespace: 'user' })
], DiscussionPage.prototype, "profile", void 0);
__decorate([
    Watch('discussion')
], DiscussionPage.prototype, "onDiscussionUpdate", null);
DiscussionPage = __decorate([
    Component({
        components: {
            Btn,
            Sidebar,
            Gallery,
            Expander,
            SocialShare,
            ProfileCard,
            CoverImage,
            ExpanderMap,
            OfferCard,
            LabelList,
            SocialLinks,
            CommentItem,
            CreateComment,
            DiscussionContent,
            Report,
            MarkdownFormatter,
            EngagementFooter,
            CommentList,
            OverlayBasic,
            MemberLookup
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | discussion',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic discussion',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/discussion` }],
            };
        },
    })
], DiscussionPage);
export default DiscussionPage;
