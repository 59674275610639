import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
// components
import TextareaInput from '@/components/FormInputs/TextAreaInput/TextAreaInput.vue';
import Btn from '@/components/Button/Btn.vue';
import CheckboxInput from '@/components/FormInputs/CheckboxInput/CheckboxInput.vue';
let CreateComment = class CreateComment extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.form = {
            commentable_id: '',
            commentable_type: '',
            comment: '',
            submit_restricted_words: false,
        };
        this.errors = {
            comment: [],
            restricted_words: [],
            submit_restricted_words: [],
        };
    }
    onCommentableIdChange() {
        this.form.commentable_id = this.commentable_id ? this.commentable_id : '';
    }
    onCommentableTypeChange() {
        this.form.commentable_type = this.commentable_type ? this.commentable_type : '';
    }
    onReplyingToChange() {
        this.form.replying_to_id = this.replying_to?.id ? this.replying_to.id : '';
    }
    mounted() {
        this.form.commentable_id = this.commentable_id ? this.commentable_id : '';
        this.form.commentable_type = this.commentable_type ? this.commentable_type : '';
        this.form.replying_to_id = this.replying_to?.id ? this.replying_to.id : '';
    }
    async cancel() {
        this.$emit('close');
    }
    async comment() {
        this.loading = true;
        try {
            if (this.replying_to && this.replying_to.id !== '') {
                this.form.replying_to_id = this.replying_to.id;
            }
            await this.$store.dispatch('engagements/createComment', this.form);
            this.loading = false;
            this.$emit('close');
        }
        catch (e) {
            if (!e.success && e.code === 1912) {
                this.errors.restricted_words = e.data.restricted_words;
            }
            this.loading = false;
        }
    }
};
__decorate([
    Prop()
], CreateComment.prototype, "commentable_id", void 0);
__decorate([
    Prop()
], CreateComment.prototype, "commentable_type", void 0);
__decorate([
    Prop()
], CreateComment.prototype, "replying_to", void 0);
__decorate([
    Watch('commentable_id')
], CreateComment.prototype, "onCommentableIdChange", null);
__decorate([
    Watch('commentable_type')
], CreateComment.prototype, "onCommentableTypeChange", null);
__decorate([
    Watch('replying_to_id')
], CreateComment.prototype, "onReplyingToChange", null);
CreateComment = __decorate([
    Component({
        components: {
            TextareaInput,
            Btn,
            CheckboxInput
        },
    })
], CreateComment);
export default CreateComment;
