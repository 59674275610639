import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
// components
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import Btn from '@/components/Button/Btn.vue';
let Report = class Report extends Vue {
    constructor() {
        super(...arguments);
        this.showForm = false;
        this.form = {
            reportable_id: '',
            reportable_type: '',
            comment: ''
        };
        this.errors = {
            comment: [],
        };
    }
    mounted() {
        this.form.reportable_id = this.reportable_id;
        this.form.reportable_type = this.reportable_type;
    }
    toggleReporting() {
        this.showForm = !this.showForm;
    }
    async report() {
        const response = await this.$store.dispatch('engagements/report', this.form);
        this.showForm = false;
    }
};
__decorate([
    Prop()
], Report.prototype, "hasReported", void 0);
__decorate([
    Prop()
], Report.prototype, "reportable_id", void 0);
__decorate([
    Prop()
], Report.prototype, "reportable_type", void 0);
__decorate([
    Prop()
], Report.prototype, "icon", void 0);
Report = __decorate([
    Component({
        components: { TextInput, Btn }
    })
], Report);
export default Report;
