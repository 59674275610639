import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
// components
import TextareaInput from '@/components/FormInputs/TextAreaInput/TextAreaInput.vue';
import Btn from '@/components/Button/Btn.vue';
import CheckboxInput from '@/components/FormInputs/CheckboxInput/CheckboxInput.vue';
let EditComment = class EditComment extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.form = {
            comment_id: this.comment.id,
            comment: this.comment.comment,
            submit_restricted_words: false,
        };
        this.errors = {
            comment: [],
            restricted_words: [],
            submit_restricted_words: [],
        };
    }
    async mounted() {
        this.form.comment_id = this.comment ? this.comment.id : '';
        this.form.comment = this.comment ? this.comment.comment : '';
    }
    async cancel() {
        this.$emit('close');
    }
    async updateComment() {
        this.loading = true;
        try {
            await this.$store.dispatch('engagements/updateComment', this.form);
            this.loading = false;
            this.$emit('close');
        }
        catch (e) {
            if (!e.success && e.code === 1912) {
                this.errors.restricted_words = e.data.restricted_words;
            }
            this.loading = false;
        }
    }
};
__decorate([
    Prop()
], EditComment.prototype, "comment", void 0);
EditComment = __decorate([
    Component({
        components: {
            TextareaInput,
            Btn,
            CheckboxInput
        },
    })
], EditComment);
export default EditComment;
