import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
//components
import ProfileCard from '@/components/ProfileCard/ProfileCard.vue';
import MarkdownFormatter from '@/components/MarkdownFormatter/MarkdownFormatter.vue';
import EngagementFooter from '@/components/Engagements/EngagementFooter/EngagementFooter.vue';
import CommentQuote from '@/components/Comments/CommentQuote/CommentQuote.vue';
let CommentItem = class CommentItem extends Vue {
    mounted() { }
    get parentId() {
        return this.replying_to_id !== '' ? this.commentable_id : null;
    }
    get parentType() {
        return this.replying_to_id !== '' ? 'comment' : null;
    }
    get replyingToComment() {
        return this.replying_to_id !== '' && this.replying_to_id == this.comment.id ? this.comment : '';
    }
};
__decorate([
    Prop()
], CommentItem.prototype, "comment", void 0);
__decorate([
    Prop({ default: '' })
], CommentItem.prototype, "replying_to_id", void 0);
__decorate([
    Prop()
], CommentItem.prototype, "commentable_id", void 0);
__decorate([
    Prop()
], CommentItem.prototype, "commentable_type", void 0);
CommentItem = __decorate([
    Component({
        components: {
            ProfileCard,
            MarkdownFormatter,
            EngagementFooter,
            CommentQuote
        },
    })
], CommentItem);
export default CommentItem;
