import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
import CommentItem from '../Comment/Comment.vue';
let Replies = class Replies extends Vue {
    constructor() {
        super(...arguments);
        this.showReplies = true;
    }
};
__decorate([
    Prop()
], Replies.prototype, "replies", void 0);
__decorate([
    Prop()
], Replies.prototype, "comment", void 0);
Replies = __decorate([
    Component({
        components: {
            CommentItem
        }
    })
], Replies);
export default Replies;
