import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
let AgreeDisagreeCount = class AgreeDisagreeCount extends Vue {
};
__decorate([
    Prop()
], AgreeDisagreeCount.prototype, "agreesCount", void 0);
__decorate([
    Prop()
], AgreeDisagreeCount.prototype, "disagreesCount", void 0);
__decorate([
    Prop()
], AgreeDisagreeCount.prototype, "commentCount", void 0);
AgreeDisagreeCount = __decorate([
    Component
], AgreeDisagreeCount);
export default AgreeDisagreeCount;
