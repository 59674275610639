import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
import CommentItem from '../Comment/Comment.vue';
import Replies from '@/components/Comments/Replies/Replies.vue';
let CommentList = class CommentList extends Vue {
};
__decorate([
    Prop()
], CommentList.prototype, "comments", void 0);
CommentList = __decorate([
    Component({
        components: {
            CommentItem,
            Replies
        }
    })
], CommentList);
export default CommentList;
