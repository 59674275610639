import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ProfileService from '@/services/user';
// components
import Btn from '@/components/Button/Btn.vue';
import DiscussionCard from '@/views/Discussions/Components/DiscussionCard/DiscussionCard.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import SearchInput from '@/components/FormInputs/SearchInput/SearchInput.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
import SelectInput from '@/components/FormInputs/SelectInput/SelectInput.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
let Discussions = class Discussions extends Vue {
    constructor() {
        super(...arguments);
        this.filteredTopic = null;
        this.searchValue = '';
        this.searchError = [];
        this.loading = true;
        this.paginationLoaded = false;
        this.showFilters = true;
        this.onlyFavourites = false;
        this.coverImageDesc = "Tell us about your safaris, considered opinions & findings. All relevant topics & opinions welcome.";
        this.searchTimeout = null;
    }
    async onTopicFilterChange(newVal) {
        this.loading = true;
        // Set the filter ID and reset page
        this.filteredTopic = newVal === '' ? null : newVal;
        await this.$store.dispatch('discussions/setFilter', newVal === '' ? null : newVal);
        if (this.$route.query.label !== newVal) {
            await this.$store.dispatch('discussions/setPage', 1);
        }
        else {
            await this.$store.dispatch('discussions/setPage', this.$route.query.page);
        }
        // Call the page load
        await this.$store.dispatch('discussions/getDiscussions');
        this.loading = false;
    }
    async searchUpdated(newVal) {
        // We do this timeout cllear timeout dance
        // so that the search is only triggered 300ms after the user has stopped typing.
        // We trigger loading immediately though to provide feedback
        clearTimeout(this.searchTimeout);
        this.loading = true;
        // Set the search term & rest page
        await this.$store.dispatch('discussions/setSearch', newVal);
        if (this.$route.query.search !== newVal) {
            await this.$store.dispatch('discussions/setPage', 1);
        }
        else {
            await this.$store.dispatch('discussions/setPage', this.$route.query.page);
        }
        let self = this;
        this.searchTimeout = setTimeout(async function () {
            // Call the page load
            await self.$store.dispatch('discussions/getDiscussions');
            self.loading = false;
        }, 500);
    }
    created() {
        if (history.state.search && history.state.label && history.state.page) {
            if (this.$route.fullPath === `/discussions?search=${history.state.search}&label=${history.state.label}&page=${history.state.page}`) {
                return;
            }
            else {
                this.$router.push({ path: `/discussions?search=${history.state.search}&label=${history.state.label}&page=${history.state.page}` });
            }
        }
        else if (history.state.search && history.state.page) {
            if (this.$route.fullPath === `/discussions?search=${history.state.search}&page=${history.state.page}`) {
                return;
            }
            else {
                this.$router.push({ path: `/discussions?search=${history.state.search}&page=${history.state.page}` });
            }
        }
        else if (history.state.label && history.state.page) {
            if (this.$route.fullPath === `/discussions?label=${history.state.label}&page=${history.state.page}`) {
                return;
            }
            else {
                this.$router.push({ path: `/discussions?label=${history.state.label}&page=${history.state.page}` });
            }
        }
    }
    async mounted() {
        await this.resetDiscussionValues();
        if (this.$route.query.search) {
            await this.$store.dispatch('discussions/setSearch', this.$route.query.search);
            this.searchValue = this.$store.getters['discussions/getSearchTerm'];
        }
        if (this.$route.query.label) {
            await this.$store.dispatch('discussions/setFilter', this.$route.query.label);
            this.filteredTopic = this.$store.getters['discussions/getFilter'];
        }
        if (this.$route.query.page) {
            await this.$store.dispatch('discussions/setPage', this.$route.query.page);
        }
        await this.$store.commit('discussions/SET_ONLY_FAVOURITES', false);
        await this.$store.dispatch('discussions/getDiscussions');
        this.paginationLoaded = true;
        await this.$store.dispatch('label/getAllLabels');
        this.loading = false;
        if (this.$store.getters['auth/loggedIn']) {
            await ProfileService.updateLastActivity("discussions");
        }
    }
    get sortedLabels() {
        let sortedLabels = this.labels;
        sortedLabels.sort((a, b) => {
            let lowerCaseA = a.name.toLowerCase();
            let lowerCaseB = b.name.toLowerCase();
            if (lowerCaseA < lowerCaseB) {
                return -1;
            }
            if (lowerCaseA > lowerCaseB) {
                return 1;
            }
            return 0;
        });
        return sortedLabels;
    }
    async beforeDestroy() {
        await this.resetDiscussionValues();
    }
    async filterFavourites() {
        this.onlyFavourites = !this.onlyFavourites;
        await this.$store.dispatch('discussions/setOnlyFavourites', this.onlyFavourites);
        await this.$store.dispatch('discussions/getDiscussions');
    }
    async toggleFilters() {
        this.showFilters = !this.showFilters;
        if (!this.showFilters) {
            this.filteredTopic = null;
            await this.$store.dispatch('discussions/setFilter', null);
            await this.$store.dispatch('discussions/setOnlyFavourites', false);
            this.filteredTopic = '';
            await this.$store.dispatch('discussions/getDiscussions');
            this.onlyFavourites = false;
        }
    }
    async resetDiscussionValues() {
        await this.$store.dispatch('discussions/setSearch', '');
        this.filteredTopic = null;
        await this.$store.dispatch('discussions/setFilter', null);
        await this.$store.dispatch('discussions/setPage', 1);
    }
    async paginate(page) {
        await this.$store.dispatch('discussions/setPage', page);
        var query = {};
        query['page'] = page.toString();
        if (this.searchValue != '') {
            query['search'] = this.searchValue;
        }
        if (this.filteredTopic != null) {
            query['label'] = this.filteredTopic;
        }
        this.$router.push({
            name: 'discussions',
            query: query,
        });
    }
};
__decorate([
    Getter('getDiscussions', { namespace: 'discussions' })
], Discussions.prototype, "discussions", void 0);
__decorate([
    Getter('pagination', { namespace: 'pagination' })
], Discussions.prototype, "pagination", void 0);
__decorate([
    Getter('getLabels', { namespace: 'label' })
], Discussions.prototype, "labels", void 0);
__decorate([
    Watch('filteredTopic')
], Discussions.prototype, "onTopicFilterChange", null);
__decorate([
    Watch('searchValue')
], Discussions.prototype, "searchUpdated", null);
Discussions = __decorate([
    Component({
        components: {
            Btn,
            DiscussionCard,
            TextInput,
            SearchInput,
            CoverImage,
            SelectInput,
            Pagination,
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | discussions',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic discussions',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/discussions` }],
            };
        },
    })
], Discussions);
export default Discussions;
